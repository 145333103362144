import React from 'react';
import Layout from '../../layout';
import SEO from '../../components/Seo/Seo';
import Header from '../../components/Header/Header';
import Intro from '../../components/Intro/Intro';

const LidWordenBedankt = () => (
  <Layout>
    <SEO title="Lid Worden – Bedankt" />
    <Header variant="5" title="Formulier in behandeling" />
    <Intro text="Houd je inbox in de gaten, we komen zo snel mogelijk bij je terug." />
  </Layout>
);
export default LidWordenBedankt;
