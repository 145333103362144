import styled from 'styled-components';

export const IntroWrapper = styled.div`
  font-style: italic;
  text-align: center;
  font-size: 1.7rem;
  margin: 2rem 0;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }

  ${props =>
    props.variant === 'mono' &&
    `
      font-family: 'fira-mono, monospace';
      font-weight: 700;
      width: 80%;
      margin-left: 5%;
      font-size: 1.5rem;
      line-height: 2em;
      text-align: justify;
    `}
`;
