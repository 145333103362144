import React from 'react';
import { IntroWrapper } from './Intro.style';
import StoryWrap from '../StoryWrap/StoryWrap.style';

const Intro = ({ text, variant }) => (
  <IntroWrapper variant={variant}>
    <StoryWrap>{text}</StoryWrap>
  </IntroWrapper>
);
export default Intro;
